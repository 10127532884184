.chart-container {
  width: 200px;
  height: 200px;
  position: relative;
  margin: 0 auto;
}

.chart-container canvas {
  margin: 0 auto;
}

.chart {
  width: 100%;
  height: 100%;
  border: 20px solid transparent;
  border-top-color: #3b82f6;
  border-radius: 50%;
  position: relative;
  transform-origin: center center;
}
.remaining-chart {
  width: 100%;
  height: 100%;
  border: 20px solid transparent;
  border-top-color: #ccc; /* Set the desired border color here */
  border-radius: 50%;
  transform-origin: center center;
}

.percentage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
