@keyframes ldio-aqs9nuz2775 {
  0% {
    opacity: 1;
    backface-visibility: hidden;
    transform: translateZ(0) scale(1.5,1.5);
  } 100% {
    opacity: 0;
    backface-visibility: hidden;
    transform: translateZ(0) scale(1,1);
  }
}
.ldio-aqs9nuz2775 div > div {
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #4263EB;
  animation: ldio-aqs9nuz2775 2.2222222222222223s linear infinite;
}.ldio-aqs9nuz2775 div:nth-child(1) > div {
  left: 148px;
  top: 88px;
  animation-delay: -1.9444444444444444s;
}
.ldio-aqs9nuz2775 > div:nth-child(1) {
  transform: rotate(0deg);
  transform-origin: 160px 100px;
}.ldio-aqs9nuz2775 div:nth-child(2) > div {
  left: 130px;
  top: 130px;
  animation-delay: -1.6666666666666665s;
}
.ldio-aqs9nuz2775 > div:nth-child(2) {
  transform: rotate(45deg);
  transform-origin: 142px 142px;
}.ldio-aqs9nuz2775 div:nth-child(3) > div {
  left: 88px;
  top: 148px;
  animation-delay: -1.3888888888888888s;
}
.ldio-aqs9nuz2775 > div:nth-child(3) {
  transform: rotate(90deg);
  transform-origin: 100px 160px;
}.ldio-aqs9nuz2775 div:nth-child(4) > div {
  left: 46px;
  top: 130px;
  animation-delay: -1.1111111111111112s;
}
.ldio-aqs9nuz2775 > div:nth-child(4) {
  transform: rotate(135deg);
  transform-origin: 58px 142px;
}.ldio-aqs9nuz2775 div:nth-child(5) > div {
  left: 28px;
  top: 88px;
  animation-delay: -0.8333333333333333s;
}
.ldio-aqs9nuz2775 > div:nth-child(5) {
  transform: rotate(180deg);
  transform-origin: 40px 100px;
}.ldio-aqs9nuz2775 div:nth-child(6) > div {
  left: 46px;
  top: 46px;
  animation-delay: -0.5555555555555556s;
}
.ldio-aqs9nuz2775 > div:nth-child(6) {
  transform: rotate(225deg);
  transform-origin: 58px 58px;
}.ldio-aqs9nuz2775 div:nth-child(7) > div {
  left: 88px;
  top: 28px;
  animation-delay: -0.2777777777777778s;
}
.ldio-aqs9nuz2775 > div:nth-child(7) {
  transform: rotate(270deg);
  transform-origin: 100px 40px;
}.ldio-aqs9nuz2775 div:nth-child(8) > div {
  left: 130px;
  top: 46px;
  animation-delay: 0s;
}
.ldio-aqs9nuz2775 > div:nth-child(8) {
  transform: rotate(315deg);
  transform-origin: 142px 58px;
}
.loadingio-spinner-spin-omxliu0y44c {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
}
.ldio-aqs9nuz2775 {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-aqs9nuz2775 div { box-sizing: content-box; }
/* generated by https://loading.io/ */