@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: "Pretendard";
  font-display: fallback;

  src: url("../assets/fonts/Pretendard-Black.otf") format("opentype");

  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Pretendard";
  font-display: fallback;

  src: url("../assets/fonts/Pretendard-ExtraBold.otf") format("opentype");

  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Pretendard";
  font-display: fallback;

  src: url("../assets/fonts/Pretendard-Bold.otf") format("opentype");

  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Pretendard";
  font-display: fallback;

  src: url("../assets/fonts/Pretendard-Medium.otf") format("opentype");

  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Pretendard";
  font-display: fallback;

  src: url("../assets/fonts/Pretendard-Regular.otf") format("opentype");

  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Pretendard";
  font-display: fallback;

  src: url("../assets/fonts/Pretendard-Thin.otf") format("opentype");

  font-weight: 200;
  font-style: normal;
}
body {
  margin: 0;
  font-family: Pretendard, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #EDF2FF;
}

code {
  font-family: Pretendard, 'Courier New', monospace;
}
