.display2lines {
  max-width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mainContent {
  padding-top: 110px;
}
.mt-header {
  margin-top: 130px;
}

.inputWrapper .static,
.inputWrapper .dynamic,
.inputWrapper select {
  grid-column: span 3 / span 3;
  width: 100%;
  background-color:white;
  border: 1px solid #f0eeee;
  border-radius: 8px;
  /* padding: 8px; */
}

.inputWrapper .static {
  background-color: #f8f8f8;
}

.row {
  display: flex;
  gap: 2px;
  padding: 8px;
  border-bottom: 1px solid #f0eeee;
}

.row svg {
  width: 20px;
}

.datePickerWrapper > div {
  width: 100%;
}